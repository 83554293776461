@import url(https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-bs4.min.css);


h3.green[data-v-90942066] {
    cursor: pointer;
    text-decoration: underline;
}


.invalid[data-v-69acbb2b] {
    display: block!important;
}


/*# sourceMappingURL=chunk-089bc85f.562a38ac.css.map*/